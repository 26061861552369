/* 4. Landing Page ================================= */
.mainContainer .projContainer {
	position: relative;
	margin: 0 auto 3px;
	top: 5px;
	height: auto;
	width: 97%;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

@media (min-width: 768px) {
	.mainContainer .projContainer {
		margin: 22px auto 3px;
		width: 98.5%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer {
		width: 99%;
		margin: 22px auto 6px;
	}
}

.mainContainer .projContainer .projectHeader {
	position: relative;
	margin: 15px auto 35px;
	width: 85%;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 45px;
	line-height: 51px;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: center;
	color: black;
	padding-top: 35px;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .projectHeader {
		margin: 45px auto 55px;
		font-size: 70px;
		line-height: 80px;
		width: 75%;
	}
}

.mainContainer .projContainer .projectSub {
	position: relative;
	margin: 0 1% 30px 4%;
	/* left: 2%; */
	width: 87%;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 22px;
	line-height: 37px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: left;
	color: black;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .projectSub {
		width: 73%;
		margin-bottom: 70px;
		font-size: 24px;
		line-height: 41px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .projectSub {
		width: 60%;
		margin-bottom: 70px;
		text-align: left;
		font-size: 26px;
		line-height: 44px;
	}
}

.mainContainer .projContainer .projectSub a {
	text-decoration: none;
	color: black;
	font-weight: 500;
	cursor: pointer;
}

.mainContainer .projContainer .heroSection {
	position: relative;
	margin: 15px auto;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.mainContainer .projContainer .heroSection .hero {
	position: relative;
	margin: 165px auto 0px;
	width: 100%;
	height: 350px;
	background: url(../assets/heroes/hopeOfVictory.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .hero {
		height: 380px;
		margin: 165px auto 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .hero {
		height: 700px;
	}
}

.mainContainer .projContainer .heroSection .hero a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.mainContainer .projContainer .heroSection .hero .playCirle {
	position: relative;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.6);
}

.mainContainer .projContainer .heroSection .hero .playCirle .playTri {
	position: absolute;
	margin: 0 auto;
	top: 50%;
	left: 31%;
	transform: translateY(-50%) scale(0.8);
	border-top: 30px solid transparent;
	border-bottom: 30px solid transparent;
	border-left: 50px solid rgba(0, 0, 0, 0.79);
}

.mainContainer .projContainer .heroSection .hero .heroFix {
	position: relative;
	margin: 0 auto;
	height: 3px;
	width: 100%;
	padding-top: 365px;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .hero .heroFix {
		padding-top: 400px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .hero .heroFix {
		padding-top: 720px;
	}
}

.mainContainer .projContainer .heroSection .hero .heroSub {
	position: relative;
	margin: 0 auto 60px;
	width: 80%;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: center;
	color: black;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .hero .heroSub {
		width: 55%;
		font-size: 24px;
		line-height: 38px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .hero .heroSub {
		width: 50%;
	}
}

.mainContainer .projContainer .heroSection .hero1 {
	margin: 5px auto 0px;
}

.mainContainer .projContainer .heroSection .hero2 {
	background: url(../assets/heroes/andyHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero3 {
	background: url(../assets/heroes/lightningHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero4 {
	background: url(../assets/heroes/squirrelHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero5 {
	background: url(../assets/heroes/aaowHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero6 {
	background: url(../assets/heroes/stingersHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero7 {
	background: url(../assets/heroes/pdxHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero8 {
	background: url(../assets/heroes/sponsorHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero9 {
	background: url(../assets/heroes/villageHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .hero10 {
	background: url(../assets/heroes/tankHero.png) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .projContainer .heroSection .lastHero {
	margin: 165px auto;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .lastHero {
		margin: 165px 0 205px .5% !important;
	}
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .small {
		width: 49.5%;
		margin: 165px .5% 0 0;
	}
}

.mainContainer .projContainer .heroSection .small .heroSub {
	width: 80%;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .small .heroSub {
		width: 85%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .small .heroSub {
		width: 60%;
	}
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .smallRight {
		width: 49.5%;
		margin: 165px 0px 0px .5%;
	}
}

.mainContainer .projContainer .heroSection .smallRight .heroSub {
	width: 80%;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .smallRight .heroSub {
		width: 85%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .smallRight .heroSub {
		width: 60%;
	}
}

.mainContainer .projContainer .heroSection .processWork {
	position: relative;
	margin: 40px auto 120px;
	width: 74%;
	padding: 30px 0px;
	border: 3px solid black;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 23px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	color: black;
	transition: .4s;
}

@media (min-width: 768px) {
	.mainContainer .projContainer .heroSection .processWork {
		margin: 90px auto 120px;
		width: 36%;
		font-size: 31px;
		padding: 35px 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projContainer .heroSection .processWork {
		width: 23%;
	}
	.mainContainer .projContainer .heroSection .processWork:hover {
		background: black;
		transition: .4s;
		color: #26ffba;
	}
}

.mainContainer .projContainer .heroSection .processWork a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mainContainer .arrowCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .arrowCont .heroSection .sectionTitle {
	position: relative;
	margin: 0 auto 25px;
	width: 60%;
	font-family: niveau-grotesk, sans-serif;
	font-size: 32px;
	font-weight: 400;
	text-align: center;
	line-height: 40px;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSection .sectionTitle {
		margin: 0px auto 50px;
		font-size: 46px;
		width: 90%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSection .sectionTitle {
		margin: 10px auto 60px;
		font-size: 50px;
	}
}

.mainContainer .arrowCont .heroSection .kioskWrap {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSection .kioskWrap {
		width: 90%;
	}
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskPhoto {
	position: relative;
	margin: 0px auto 30px;
	width: 85%;
	height: 550px;
	box-shadow: 0px 1px 10px -3px #565656;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSection .kioskWrap .kioskPhoto {
		width: 33%;
		height: 350px;
		box-shadow: 0px 2px 5px -4px #565656;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSection .kioskWrap .kioskPhoto {
		width: 33%;
		height: 680px;
		box-shadow: 0px 2px 5px -4px #565656;
	}
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskOne {
	background: url(../assets/heroes/arrow/kiosk1.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskTwo {
	background: url(../assets/heroes/arrow/kiosk2.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskThree {
	background: url(../assets/heroes/arrow/kiosk3.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskFour {
	background: url(../assets/heroes/arrow/kiosk4.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskFive {
	background: url(../assets/heroes/arrow/kiosk5.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrap .kioskSix {
	background: url(../assets/heroes/arrow/kiosk6.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSection .kioskWrapTwo {
	margin-top: 20px;
}

.mainContainer .arrowCont .heroSection .kioskSub {
	position: relative;
	margin: -10px auto 60px;
	width: 80%;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: center;
	color: black;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSection .kioskSub {
		width: 65%;
		font-size: 24px;
		line-height: 41px;
		margin-top: -5px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSection .kioskSub {
		width: 50%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSectionTwo {
		margin-top: 70px;
	}
}

.mainContainer .arrowCont .heroSectionTwo .hero {
	height: 205px;
	margin-bottom: 50px;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSectionTwo .hero {
		height: 450px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSectionTwo .hero {
		height: 800px;
	}
}

.mainContainer .arrowCont .heroSectionTwo .hero .heroSub {
	padding-top: 220px;
	margin: 0px auto 50px;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSectionTwo .hero .heroSub {
		padding-top: 460px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSectionTwo .hero .heroSub {
		padding-top: 820px;
	}
}

.mainContainer .arrowCont .heroSectionTwo .hero1 {
	background: url(../assets/heroes/vidwallMain.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSectionTwo .hero2 {
	background: url(../assets/heroes/vidwall2.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSectionTwo .hero3 {
	background: url(../assets/heroes/vidwallHome.jpg) no-repeat;
	background-size: contain;
	background-position: center;
}

.mainContainer .arrowCont .heroSectionTwo .processCont {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	width: 100%;
	margin: 120px auto 80px;
}

.mainContainer .arrowCont .heroSectionTwo .processCont .processWork {
	margin: 20px auto;
}

@media (min-width: 768px) {
	.mainContainer .arrowCont .heroSectionTwo .processCont .processWork {
		width: 46%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .arrowCont .heroSectionTwo .processCont .processWork {
		width: 24%;
	}
}

@media (min-width: 1680px) {
	.mainContainer .arrowCont .heroSectionTwo .processCont .processWork {
		width: 22%;
	}
}

.mainContainer .destinyCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .destinyCont .heroSection .hero {
	margin: 5px auto 0px;
}

.mainContainer .destinyCont .heroSection .hero .heroFix {
	padding-top: 265px;
}

@media (min-width: 768px) {
	.mainContainer .destinyCont .heroSection .hero .heroFix {
		padding-top: 290px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .destinyCont .heroSection .hero .heroFix {
		padding-top: 615px;
	}
}

.mainContainer .destinyCont .heroSection .hero .heroSub {
	padding-top: 105px;
}

.mainContainer .destinyCont .heroSection .hero1 {
	background: url(../assets/heroes/destinyBG.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .destinyCont .heroSection .hero2 {
	background: url(../assets/heroes/destinyMobile.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .destinyCont .heroSection .hero3 {
	background: url(../assets/heroes/destinyCarousel.gif) no-repeat black;
	background-size: contain;
	background-position: center;
	margin: 145px auto;
}

@media (min-width: 768px) {
	.mainContainer .destinyCont .heroSection .smallRight {
		width: 49.5%;
		margin: 5px 0px 175px .5%;
	}
	.mainContainer .destinyCont .heroSection .smallRight .heroSub {
		width: 80%;
	}
}

.mainContainer .fizzCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .fizzCont .heroSection .hero {
	background: url(../assets/thumbs/fizzMap.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .mediacracyCont .projectSub span {
	font-weight: 600;
}

.mainContainer .mediacracyCont .heroSection {
	position: relative;
	margin: 15px auto 0px;
	width: 100%;
	height: auto;
}

.mainContainer .mediacracyCont .heroSection .hero {
	background: url(../assets/heroes/mediacracy.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

@media (min-width: 768px) {
	.mainContainer .disneyCont .projectHeader {
		width: 60%;
	}
}

.mainContainer .disneyCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .disneyCont .heroSection .hero {
	background: url(../assets/thumbs/bengal.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .siriusCont .projectSub {
	margin: 0 1% 30px 4%;
}

@media (min-width: 768px) {
	.mainContainer .siriusCont .projectSub {
		margin: 0 1% 55px 4%;
	}
}

.mainContainer .siriusCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .siriusCont .heroSection .hero {
	background: url(../assets/heroes/sirius.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .siriusCont .heroSection .hero .heroSub {
	margin: 150px auto 100px;
}

.mainContainer .siriusCont .heroSection .hero2 {
	background: url(../assets/heroes/Car_Interior_A.gif) no-repeat;
	background-size: cover;
	background-position-x: -200px;
}

@media (min-width: 768px) {
	.mainContainer .siriusCont .heroSection .hero2 {
		background-position-x: 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .siriusCont .heroSection .hero2 {
		width: 49.5%;
		height: 500px;
	}
}

.mainContainer .siriusCont .heroSection .hero3 {
	background: url(../assets/heroes/Car_Interior_B.gif) no-repeat;
	background-size: cover;
	background-position-x: -200px;
}

@media (min-width: 768px) {
	.mainContainer .siriusCont .heroSection .hero3 {
		background-position-x: 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .siriusCont .heroSection .hero3 {
		width: 49.5%;
		height: 500px;
	}
}

.mainContainer .starbucksCont .projectSub {
	margin: 0 1% 30px 4%;
}

.mainContainer .starbucksCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .starbucksCont .heroSection .hero {
	background: url(../assets/thumbs/starbucks.svg) no-repeat;
	background-size: contain;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .starbucksCont .heroSection .hero .heroSub {
	margin: 150px auto 100px;
}

.mainContainer .starbucksCont .heroSection .processWork {
	margin: 60px auto 100px;
}

@media (min-width: 1440px) {
	.mainContainer .starbucksCont .heroSection .processWork {
		margin: 80px auto 150px;
	}
}

.mainContainer .victoryCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .victoryCont .heroSection .hero {
	background: url(../assets/heroes/hopeofvictory.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .thronesCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .thronesCont .heroSection .hero {
	background: url(../assets/heroes/thrones.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .heismanCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .heismanCont .heroSection .hero {
	background: url(../assets/thumbs/heisman.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .partherealCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .partherealCont .heroSection .hero {
	background: url(../assets/thumbs/particles.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.mainContainer .elderscrollsCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .elderscrollsCont .heroSection .hero {
	background: url(../assets/heroes/elderscrolls.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
	height: 410px;
}

@media (min-width: 768px) {
	.mainContainer .elderscrollsCont .heroSection .hero {
		height: 850px;
	}
}

.mainContainer .elderscrollsCont .subTwo {
	margin-top: 60px;
}

.mainContainer .touchsourceCont .heroSection {
	margin-bottom: 0px;
}

.mainContainer .touchsourceCont .heroSection .hero {
	background: url(../assets/heroes/userJourney.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	margin: 5px auto 0px;
}

.resumeContainer {
	font-family: niveau-grotesk, sans-serif;
}

.resumeContainer .sectionOne {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
	margin: 5px auto -1px;
	width: 97%;
	background: white;
}

@media (min-width: 768px) {
	.resumeContainer .sectionOne {
		width: 98.5%;
		margin: 26px auto 0px;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .sectionOne {
		width: 99%;
	}
}

.resumeContainer .sectionOne h1 {
	width: 100%;
	margin: 50px auto 0px;
	font-size: 42px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	line-height: 47px;
	letter-spacing: -.5px;
}

@media (min-width: 768px) {
	.resumeContainer .sectionOne h1 {
		margin: 60px auto 0px;
		font-size: 72px;
		line-height: 77px;
		letter-spacing: -1px;
	}
}

.resumeContainer .sectionOne h2 {
	width: 100%;
	margin: 20px auto 25px;
	font-size: 21px;
	font-weight: 500;
	text-transform: lowercase;
	text-align: center;
	letter-spacing: .5px;
}

@media (min-width: 768px) {
	.resumeContainer .sectionOne h2 {
		margin: 35px auto 25px;
		font-size: 42px;
	}
}

.resumeContainer .sectionOne h3 {
	width: 100%;
	margin: 10px auto 4px;
	font-size: 16px;
	font-weight: 400;
	text-transform: lowercase;
	text-align: center;
}

@media (min-width: 768px) {
	.resumeContainer .sectionOne h3 {
		margin: 10px auto 8px;
		font-size: 27px;
	}
}

.resumeContainer .sectionOne .divLine {
	position: relative;
	margin: 40px auto 0px;
	width: 80%;
	height: 3px;
	background: black;
}

@media (min-width: 768px) {
	.resumeContainer .sectionOne .divLine {
		height: 2px;
		width: 75%;
	}
}

.resumeContainer .bodySection {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto -1px;
	text-align: center;
	width: 97%;
	background: white;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection {
		width: 98.5%;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .bodySection {
		width: 99%;
	}
}

.resumeContainer .bodySection h1 {
	margin: 40px auto 0px;
	font-size: 37px;
	font-weight: 600;
	width: 100%;
	position: relative;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection h1 {
		margin: 50px auto 0px;
		font-size: 56px;
		font-weight: 600;
	}
}

.resumeContainer .bodySection h2 {
	position: relative;
	margin: 20px auto 0px;
	font-size: 30px;
	font-weight: 400;
	width: 100%;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection h2 {
		margin: 20px auto 0px;
		font-size: 40px;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .bodySection h2 {
		margin: 50px auto 0px;
	}
}

.resumeContainer .bodySection h3 {
	position: relative;
	margin: 15px auto 0px;
	font-size: 20px;
	font-weight: 300;
	width: 90%;
	line-height: 33px;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection h3 {
		margin: 15px auto 0px;
		font-size: 26px;
		line-height: 40px;
	}
}

.resumeContainer .bodySection .clearFix {
	position: relative;
	margin: 20px auto 0px;
	background: none;
	height: 1px;
	width: 100%;
}

.resumeContainer .bodySection .subCont {
	position: relative;
	width: 85%;
	margin-top: 10px;
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection .subCont {
		margin-top: 30px;
		width: 47%;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .bodySection .subCont {
		width: 35%;
		margin-top: 10px;
	}
}

.resumeContainer .bodySection .subCont h2 {
	font-size: 28px;
	font-weight: 500;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection .subCont h2 {
		font-size: 39px;
	}
}

.resumeContainer .bodySection .subCont h3 {
	font-size: 22px;
	font-weight: 400;
	margin-top: 11px;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection .subCont h3 {
		font-size: 30px;
		margin-top: 14px;
		margin-bottom: 24px;
	}
}

.resumeContainer .bodySection .subCont h4 {
	font-size: 20px;
	line-height: 33px;
	font-weight: 300;
	width: 95%;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection .subCont h4 {
		font-size: 23px;
		line-height: 36px;
	}
}

.resumeContainer .bodySection ul {
	position: relative;
	margin: 0px auto 8px;
	width: 76%;
	font-size: 20px;
	font-weight: 300;
	line-height: 32px;
	text-align: left;
	list-style: circle;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection ul {
		font-size: 24px;
		line-height: 37px;
		margin-top: 25px;
		padding: 0px 34px;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .bodySection ul {
		font-size: 25px;
		line-height: 41px;
		margin-top: 25px;
		padding: 0px 55px;
	}
}

.resumeContainer .bodySection ul li {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection ul li {
		margin-top: 18px;
	}
}

.resumeContainer .bodySection .divLine {
	position: relative;
	margin: 30px auto 0px;
	width: 80%;
	height: 3px;
	background: black;
}

@media (min-width: 768px) {
	.resumeContainer .bodySection .divLine {
		margin: 50px auto 0px;
		width: 75%;
	}
}

@media (min-width: 768px) {
	.resumeContainer .sectionFour {
		margin-bottom: 2px;
	}
}

.resumeContainer .sectionFour .lastPara li:nth-child(2) {
	margin-bottom: 40px;
}

@media (min-width: 1440px) {
	.resumeContainer .sectionFour .lastPara li:nth-child(2) {
		margin-bottom: 80px;
	}
}

.resumeContainer .sectionFour .processWork {
	position: relative;
	margin: 40px auto 120px;
	width: 74%;
	padding: 30px 0px;
	border: 3px solid black;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 23px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	color: black;
	transition: .4s;
}

@media (min-width: 768px) {
	.resumeContainer .sectionFour .processWork {
		margin: 90px auto 120px;
		width: 45%;
		font-size: 31px;
		padding: 35px 0px;
	}
}

@media (min-width: 1440px) {
	.resumeContainer .sectionFour .processWork {
		width: 23%;
		margin-left: 38%;
		margin-bottom: 170px;
		margin-top: 40px;
	}
	.resumeContainer .sectionFour .processWork:hover {
		background: black;
		transition: .4s;
		color: #26ffba;
	}
}

.resumeContainer .sectionFour .processWork a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (min-width: 1440px) {
	.resumeContainer .sectionFour ul, .resumeContainer .sectionThree ul {
		padding: 0px 0px;
	}
}

@media (min-width: 1660px) {
	.resumeContainer .sectionFour ul, .resumeContainer .sectionThree ul {
		padding: 0px 50px;
	}
}

.contactContainer {
	background: white;
}

.contactContainer h1 {
	position: relative;
	margin: 40px auto 0px;
	width: 85%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 66px;
	font-weight: 600;
	letter-spacing: -1px;
	text-transform: uppercase;
	text-align: center;
	color: black;
}

@media (min-width: 768px) {
	.contactContainer h1 {
		font-size: 110px;
		margin-top: 120px;
	}
}

.contactContainer h2 {
	position: relative;
	margin: 25px auto 0px;
	width: 100%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 26px;
	line-height: 43px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: center;
	color: black;
}

@media (min-width: 768px) {
	.contactContainer h2 {
		font-size: 41px;
		line-height: 65px;
	}
}

.contactContainer .linkSection {
	position: relative;
	margin: 70px auto 100px;
	width: 86%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
}

@media (min-width: 768px) {
	.contactContainer .linkSection {
		width: 60%;
	}
}

@media (min-width: 1440px) {
	.contactContainer .linkSection {
		width: 35%;
	}
}

.contactContainer .linkSection .linkBox {
	position: relative;
	margin: 0 auto;
	width: 90px;
	height: 90px;
}

@media (min-width: 768px) {
	.contactContainer .linkSection .linkBox {
		width: 140px;
		height: 140px;
	}
}

@media (min-width: 1440px) {
	.contactContainer .linkSection .linkBox {
		width: 120px;
		height: 120px;
		filter: grayscale(1);
		transition: .3s;
		transform: scale(1);
	}
	.contactContainer .linkSection .linkBox:hover {
		filter: grayscale(0);
		transition: .3s;
		transform: scale(1.3);
	}
}

.contactContainer .linkSection .linkBox a {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.contactContainer .linkSection .liBox {
	background: url(../assets/thumbs/linkedin.svg) no-repeat;
	background-size: 90%;
	background-position: center;
}

.contactContainer .linkSection .gmailBox {
	background: url(../assets/thumbs/gmail.png) no-repeat;
	background-size: contain;
	background-position: center;
}

.aboutContainer .mainPhoto {
	background: url(../assets/thumbs/aboutPhoto.jpg) no-repeat;
	background-size: cover;
	position: relative;
	margin: 5px auto 0px;
	width: 97%;
	height: 260px;
}

@media (min-width: 768px) {
	.aboutContainer .mainPhoto {
		width: 98.5%;
		height: 500px;
		background-position: center;
		margin: 25px auto 0px;
	}
}

@media (min-width: 1440px) {
	.aboutContainer .mainPhoto {
		width: 99%;
		height: 600px;
	}
}

.aboutContainer h2 {
	position: relative;
	margin: 0px auto;
	padding: 50px 0px 40px;
	width: 97%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 52px;
	line-height: 43px;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: center;
	color: black;
	background: white;
}

@media (min-width: 768px) {
	.aboutContainer h2 {
		padding: 90px 0px 60px;
		font-size: 90px;
		width: 98.5%;
	}
}

@media (min-width: 1440px) {
	.aboutContainer h2 {
		width: 99%;
	}
}

.aboutContainer h3 {
	position: relative;
	margin: 0px auto;
	padding: 0px 20px 0px 27px;
	width: 97%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 20px;
	line-height: 32px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: left;
	color: black;
	background: white;
}

@media (min-width: 768px) {
	.aboutContainer h3 {
		width: 98.5%;
		font-size: 24px;
		line-height: 37px;
		padding: 0px 120px 0px 127px;
	}
}

@media (min-width: 1440px) {
	.aboutContainer h3 {
		width: 99%;
		padding: 10px 20% 0px 20%;
		font-size: 26px;
		line-height: 43px;
	}
}

.aboutContainer h1 {
	position: relative;
	margin: 0px auto;
	padding: 65px 0px 35px;
	width: 97%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 52px;
	line-height: 43px;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: center;
	color: black;
	background: white;
}

@media (min-width: 768px) {
	.aboutContainer h1 {
		width: 98.5%;
		padding: 100px 0px 60px;
		font-size: 90px;
	}
}

@media (min-width: 1440px) {
	.aboutContainer h1 {
		width: 99%;
	}
}

.aboutContainer ul {
	position: relative;
	margin: 0px auto;
	padding-bottom: 60px;
	padding-left: 12%;
	width: 97%;
	height: auto;
	font-family: niveau-grotesk, sans-serif, sans-serif;
	font-size: 24px;
	line-height: 53px;
	font-weight: 300;
	letter-spacing: 0px;
	text-align: left;
	color: black;
	background: white;
	list-style: circle;
}

@media (min-width: 768px) {
	.aboutContainer ul {
		width: 98.5%;
		font-size: 29px;
		line-height: 64px;
		padding: 0px 15% 80px 31%;
	}
}

@media (min-width: 1440px) {
	.aboutContainer ul {
		width: 99%;
		padding: 0px 20% 80px 39%;
	}
}
