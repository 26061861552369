$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;


/* 4. Landing Page ================================= */

.mainContainer {
	
	////////////   TEGNA   ////////////////

	.projContainer {
		position: relative;
		margin: 0 auto 3px;
		top: 5px;
		height: auto;
		width: 97%;
		background: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

	    @media( min-width: 768px ) {
	    	margin: 22px auto 3px;
	    	width: 98.5%;
	    }

	    @media( min-width: 1440px ) {
	    	width: 99%;
	    	margin: 22px auto 6px;
	    }

		.projectHeader {
		    position: relative;
		    margin: 15px auto 35px;
		    width: 85%;
		    font-family: $mainFont, sans-serif;
		    font-size: 45px;
		    line-height: 51px;
		    font-weight: 600;
		    letter-spacing: 0px;
		    text-align: center;
		    color: black;
		    padding-top: 35px;

		    @media( min-width: 768px ) {
		    	margin: 45px auto 55px;
				font-size: 70px;
				line-height: 80px;
				width: 75%;
		    }
		}

		.projectSub {
		    position: relative;
		    margin: 0 1% 30px 4%;
		    /* left: 2%; */
		    width: 87%;
		    font-family: $mainFont, sans-serif;
		    font-size: 22px;
		    line-height: 37px;
		    font-weight: 300;
		    letter-spacing: 0px;
		    text-align: left;
		    color: black;

		    @media( min-width: 768px ) {
				width: 73%;
				margin-bottom: 70px;
				font-size: 24px;
			    line-height: 41px;
		    }

		    @media( min-width: 1440px ) {
				width: 60%;
			    margin-bottom: 70px;
			    text-align: left;
			    font-size: 26px;
			    line-height: 44px;
		    }

			a {
				text-decoration: none;
				color: black;
				font-weight: 500;
				cursor: pointer;
			}
		}

		.heroSection {
			position: relative;
			margin: 15px auto;
			width: 100%;
			height: auto;
			display: flex;
    		flex-direction: column;

    		@media( min-width: 768px ) {
		    	flex-direction: row;
		    	flex-wrap: wrap;
		    }

			.hero {
				position: relative;
				margin: 165px auto 0px;
				width: 100%;
				height: 350px;
				background: url(../assets/heroes/hopeOfVictory.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				// border: 2px solid green;

				@media( min-width: 768px ) {
			    	height: 380px; 
			    	margin: 165px auto 0px;
			    }

			    @media( min-width: 1440px ) {
			    	height: 700px; 
			    }

				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 100;
				}

				.playCirle {
					position: relative;
					margin: 0 auto;
					top: 50%;
					transform: translateY(-50%);
					width: 100px;
					height: 100px;
					border-radius: 50%;
					background: rgba(255, 255, 255, .6);

					.playTri {
						position: absolute;
					    margin: 0 auto;
					    top: 50%;
					    left: 31%;
					    transform: translateY(-50%) scale(.8);
					    border-top: 30px solid transparent;
					    border-bottom: 30px solid transparent;
					    border-left: 50px solid rgba(0, 0, 0, 0.79);
					}
				}

				.heroFix {
					position: relative;
					margin: 0 auto;
					height: 3px;
					width: 100%;
					padding-top: 365px;
					// border: 1px solid red;

					@media( min-width: 768px ) {
				    	padding-top: 400px; 
				    }

				    @media( min-width: 1440px ) {
				    	padding-top: 720px; 
				    }
				}

				.heroSub {
				    position: relative;
				    margin: 0 auto 60px;
				    width: 80%;
				    font-family: $mainFont, sans-serif;
				    font-size: 20px;
				    line-height: 30px;
				    font-weight: 300;
				    letter-spacing: 0px;
				    text-align: center;
				    color: black;

				    @media( min-width: 768px ) {
						width: 55%;
					    font-size: 24px;
					    line-height: 38px;
				    }

				    @media( min-width: 1440px ) {
						width: 50%;
				    }
				}
			}

			.hero1 {
				margin: 5px auto 0px;
			}

			.hero2 {
				background: url(../assets/heroes/andyHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero3 {
				background: url(../assets/heroes/lightningHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero4 {
				background: url(../assets/heroes/squirrelHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero5 {
				background: url(../assets/heroes/aaowHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero6 {
				background: url(../assets/heroes/stingersHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero7 {
				background: url(../assets/heroes/pdxHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero8 {
				background: url(../assets/heroes/sponsorHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero9 {
				background: url(../assets/heroes/villageHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero10 {
				background: url(../assets/heroes/tankHero.png) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.lastHero {
				margin: 165px auto;

			    @media( min-width: 768px ) {
			    	margin: 165px 0 205px .5% !important;
			    }
			}

			.small {
				@media(min-width: 768px) {
				    width: 49.5%;
					margin: 165px .5% 0 0;
				}

				.heroSub {
					width: 80%;

					@media( min-width: 768px ) {
						width: 85%;
				    }

				    @media( min-width: 1440px ) {
						width: 60%;
				    }
				}
			}

			.smallRight {
				@media(min-width: 768px) {
					width: 49.5%;
    				margin: 165px 0px 0px .5%;
				}

				.heroSub {
					width: 80%;

					@media( min-width: 768px ) {
						width: 85%;
				    }

				    @media( min-width: 1440px ) {
						width: 60%;
				    }
				}
			}

			.processWork {
			    position: relative;
			    margin: 40px auto 120px;
			    width: 74%;
			    padding: 30px 0px;
			    border: 3px solid black;
			    font-family: $mainFont, sans-serif;
			    font-size: 23px;
			    font-weight: 400;
			    letter-spacing: 1px;
			    text-align: center;
			    text-decoration: none;
			    color: black;
			    transition: .4s;

			    @media( min-width: 768px ) {
	    	        margin: 90px auto 120px;
				    width: 36%;
				    font-size: 31px;
				    padding: 35px 0px; 
			    }

			    @media( min-width: 1440px ) {
				    width: 23%;

				    &:hover {
				    	background: black;
				    	transition: .4s;
				    	color: #26ffba;
				    }
			    }

			    a {
			    	position: absolute;
			    	top: 0;
			    	left: 0;
			    	width: 100%;
			    	height: 100%;
			    }
			}
		}
	}





	////////////   ARROW IOT LAB   ////////////////

	.arrowCont {

		.heroSection {
			margin-bottom: 0px;

			.sectionTitle {
				position: relative;
				margin: 0 auto 25px;
				width: 60%;
				font-family: $mainFont;
				font-size: 32px;
				font-weight: 400;
				text-align: center;
				line-height: 40px;

				@media( min-width: 768px ) {
			    	margin: 0px auto 50px;
				    font-size: 46px;
				    width: 90%;
			    }

			    @media( min-width: 1440px ) {
			    	margin: 10px auto 60px;
			    	font-size: 50px;
			    }
			}


			.kioskWrap {
				position: relative;
				margin: 0 auto;
				width: 100%; 
				height: auto;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-around;
				align-items: center;

				@media( min-width: 1440px ) {
			    	width: 90%;
			    }	

				.kioskPhoto {
					position: relative;
					margin: 0px auto 30px;
					width: 85%;
					height: 550px;
					box-shadow: 0px 1px 10px -3px #565656;

					@media( min-width: 768px ) {
				    	width: 33%;
				    	height: 350px;
				    	box-shadow: 0px 2px 5px -4px #565656;
				    }

				    @media( min-width: 1440px ) {
				    	width: 33%;
				    	height: 680px;
				    	box-shadow: 0px 2px 5px -4px #565656;
				    }
				}

				.kioskOne {
					background: url(../assets/heroes/arrow/kiosk1.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}
				.kioskTwo {
					background: url(../assets/heroes/arrow/kiosk2.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}
				.kioskThree {
					background: url(../assets/heroes/arrow/kiosk3.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}
				.kioskFour {
					background: url(../assets/heroes/arrow/kiosk4.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}

				.kioskFive {
					background: url(../assets/heroes/arrow/kiosk5.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}
				.kioskSix {
					background: url(../assets/heroes/arrow/kiosk6.jpg) no-repeat;
					background-size: contain;
					background-position: center;
				}
			}

			.kioskWrapTwo {
				margin-top: 20px;
			}

			.kioskSub {
				position: relative;
			    margin: -10px auto 60px;
			    width: 80%;
			    font-family: $mainFont, sans-serif;
			    font-size: 20px;
			    line-height: 30px;
			    font-weight: 300;
			    letter-spacing: 0px;
			    text-align: center;
			    color: black;

			    @media(min-width: 768px) {
					width: 65%;
					font-size: 24px;
			    	line-height: 41px;
			    	margin-top: -5px;
				}

				@media(min-width: 1440px) {
					width: 50%;
				}
			}
		}

		.heroSectionTwo {
			@media(min-width: 1440px) {
				margin-top: 70px;
			}

			.hero {
				height: 205px;
				margin-bottom: 50px;

				@media(min-width: 768px) {
					height: 450px;
				}

				@media(min-width: 1440px) {
					height: 800px;
				}

				.heroSub {
					padding-top: 220px;
					margin: 0px auto 50px;

					@media(min-width: 768px) {
						padding-top: 460px;
					}

					@media(min-width: 1440px) {
						padding-top: 820px;
					}
				}
			}

			.hero1 {
				background: url(../assets/heroes/vidwallMain.jpg) no-repeat;
				background-size: contain;
				background-position: center;
				
			}

			.hero2 {
				background: url(../assets/heroes/vidwall2.jpg) no-repeat;
				background-size: contain;
				background-position: center;
			}

			.hero3 {	
				background: url(../assets/heroes/vidwallHome.jpg) no-repeat;
				background-size: contain;
				background-position: center;
			}

			.processCont {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				position: relative;
				width: 100%;
				margin: 120px auto 80px;

				.processWork {
					margin: 20px auto;

					@media (min-width: 768px) {
						width: 46%;
					}

					@media (min-width: 1440px) {
						width: 24%;
					}

					@media (min-width: 1680px) {
						width: 22%;
					}
				}
			}
		}
	}







	////////////   DESTINY   ////////////////

	.destinyCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				margin: 5px auto 0px;

				.heroFix {
					padding-top: 265px;

					@media( min-width: 768px ) {
			    	    padding-top: 290px;
				    }

				    @media( min-width: 1440px ) {
			    	    padding-top: 615px;
				    }
				}

				.heroSub {
					padding-top: 105px;
				}
			}

			.hero1 {
				background: url(../assets/heroes/destinyBG.jpg) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero2 {
				background: url(../assets/heroes/destinyMobile.jpg) no-repeat;
				background-size: cover;
				background-position: center;
			}

			.hero3 {
				background: url(../assets/heroes/destinyCarousel.gif) no-repeat black;
				background-size: contain;
				background-position: center;
				margin: 145px auto;
			}

			.smallRight {
				@media(min-width: 768px) {
					width: 49.5%;
    				margin: 5px 0px 175px .5%;

    				.heroSub {
						width: 80%;
					}
				}
			}
		}
	}




	////////////   PARTHEREAL   ////////////////

	.fizzCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/thumbs/fizzMap.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}



	////////////   MEDIACRACY   ////////////////

	.mediacracyCont {

		.projectSub {
			span {
				font-weight: 600;
			}
		}

		.heroSection {
			position: relative;
			margin: 15px auto 0px;
			width: 100%;
			height: auto;

			.hero {
				background: url(../assets/heroes/mediacracy.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}




	////////////   DISNEY CONSERVATION FUND   ////////////////

	.disneyCont {

		.projectHeader {
			@media(min-width: 768px) {
				width: 60%;
			}
		}

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/thumbs/bengal.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}



	////////////   SIRIUS XM   ////////////////

	.siriusCont {

		.projectSub {
			margin: 0 1% 30px 4%;

			@media(min-width: 768px) {
				margin: 0 1% 55px 4%;
			}
		}

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/heroes/sirius.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;

				.heroSub {
					margin: 150px auto 100px;
				}
			}

			.hero2 {
				background: url(../assets/heroes/Car_Interior_A.gif) no-repeat;
				background-size: cover;
				background-position-x: -200px;

				@media( min-width: 768px ) {
		    	    background-position-x: 0px;
			    }

			    @media( min-width: 1440px ) {
		    	    width: 49.5%;
		    	    height: 500px;
			    }
			}

			.hero3 {
				background: url(../assets/heroes/Car_Interior_B.gif) no-repeat;
				background-size: cover;
				background-position-x: -200px;

				@media( min-width: 768px ) {
		    	    background-position-x: 0px;
			    }

			    @media( min-width: 1440px ) {
		    	    width: 49.5%;
		    	    height: 500px;
			    }
			}
		}
	}



	////////////   STARBUCKS   ////////////////

	.starbucksCont {

		.projectSub {
			margin: 0 1% 30px 4%;
		}

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/thumbs/starbucks.svg) no-repeat;
				background-size: contain;
				background-position: center;
				margin: 5px auto 0px;

				.heroSub {
					margin: 150px auto 100px;
				}
			}

			.processWork {
				margin: 60px auto 100px;

				@media( min-width: 1440px ) {
		    	    margin: 80px auto 150px
			    }
			}
		}
	}






	////////////   HOPE OF VICTORY   ////////////////

	.victoryCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/heroes/hopeofvictory.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}





	////////////   GAME OF THRONES   ////////////////

	.thronesCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/heroes/thrones.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}




	////////////   HEISMAN 2019 TROPHY RACE   ////////////////

	.heismanCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/thumbs/heisman.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}




	////////////   PARTHEREAL   ////////////////

	.partherealCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/thumbs/particles.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}



	////////////   THE ELDER SCROLLS VALENWOOD   ////////////////

	.elderscrollsCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/heroes/elderscrolls.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
				height: 410px;

				@media( min-width: 768px ) {
					height: 850px;
				}
			}
		}

		.subTwo {
			margin-top: 60px;
		}
	}



	////////////   TOUCHSOURCE   ////////////////

	.touchsourceCont {

		.heroSection {
			margin-bottom: 0px;

			.hero {
				background: url(../assets/heroes/userJourney.jpg) no-repeat;
				background-size: cover;
				background-position: center;
				margin: 5px auto 0px;
			}
		}
	}


}












////////////   RESUME   ////////////////


.resumeContainer {
	// background: white;
	font-family: $mainFont;

	.sectionOne {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		margin: 5px auto -1px;
		width: 97%;
		background: white;

		@media ( min-width: 768px ) {
	        width: 98.5%;
	        margin: 26px auto 0px;
	    }

	    @media ( min-width: 1440px ) {
	        width: 99%;
	    }

		h1 {
			width: 100%;
		    margin: 50px auto 0px;
		    font-size: 42px;
		    font-weight: 600;
		    text-transform: uppercase;
		    text-align: center;
		    line-height: 47px;
		    letter-spacing: -.5px;

		    @media ( min-width: 768px ) {
	            margin: 60px auto 0px;
			    font-size: 72px;
			    line-height: 77px;
			    letter-spacing: -1px;
		    }
		}

		h2 {
		    width: 100%;
		    margin: 20px auto 25px;
		    font-size: 21px;
		    font-weight: 500;
		    text-transform: lowercase;
		    text-align: center;
		    letter-spacing: .5px;

		    @media ( min-width: 768px ) {
			    margin: 35px auto 25px;
			    font-size: 42px;
		    }
		}

		h3 {
		    width: 100%;
		    margin: 10px auto 4px;
		    font-size: 16px;
		    font-weight: 400;
		    text-transform: lowercase;
		    text-align: center;

		    @media ( min-width: 768px ) {
			    margin: 10px auto 8px;
		    	font-size: 27px;
		    }
		}

		.divLine {
			position: relative;
			margin: 40px auto 0px;
			width: 80%;
			height: 3px;
			background: black;

			@media ( min-width: 768px ) {
			    height: 2px;
			    width: 75%;
		    }
		}
	}

	.bodySection {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto -1px;
		text-align: center;
		width: 97%;
		background: white;

		@media ( min-width: 768px ) {
	        width: 98.5%;
	    }

	    @media ( min-width: 1440px ) {
	        width: 99%;
	    }

		h1 {
			margin: 40px auto 0px;
		    font-size: 37px;
		    font-weight: 600;
		    width: 100%;
		    position: relative;
		    text-transform: uppercase;

		    @media ( min-width: 768px ) {
		        margin: 50px auto 0px;
			    font-size: 56px;
			    font-weight: 600;
		    }
		}

		h2 {
		    position: relative;
		    margin: 20px auto 0px;
		    font-size: 30px;
		    font-weight: 400;
		    width: 100%;

		    @media ( min-width: 768px ) {
		        margin: 20px auto 0px;
		        font-size: 40px;
		    }

		    @media ( min-width: 1440px ) {
		        margin: 50px auto 0px;
		    }
		}

		h3 {
	        position: relative;
	        margin: 15px auto 0px;
		    font-size: 20px;
		    font-weight: 300;
		    width: 90%;
		    line-height: 33px;

		    @media ( min-width: 768px ) {
		        margin: 15px auto 0px;
			    font-size: 26px;
			    line-height: 40px;
		    }
		}

		.clearFix {
			position: relative;
			margin: 20px auto 0px;
			background: none;
			height: 1px;
			width: 100%;
		}

		.subCont {
		    position: relative;
		    width: 85%;
		    margin-top: 10px;
		    margin-bottom: 15px;

		    @media ( min-width: 768px ) {
			    margin-top: 30px;
			    width: 47%;
		    }

		    @media ( min-width: 1440px ) {
			    width: 35%;
			    margin-top: 10px;
		    }

			h2 {
				font-size: 28px;
				font-weight: 500;

				@media ( min-width: 768px ) {
				    font-size: 39px;
			    }
			}

			h3 {
			    font-size: 22px;
			    font-weight: 400;
			    margin-top: 11px;
			    margin-bottom: 10px;

			    @media ( min-width: 768px ) {
				    font-size: 30px;
				    margin-top: 14px;
				    margin-bottom: 24px;
			    }
			}

			h4 {
				font-size: 20px;
				line-height: 33px;
			    font-weight: 300;
			    width: 95%;

			    @media ( min-width: 768px ) {
			    	font-size: 23px;
			    	line-height: 36px;
			    }
			}
		}

		ul {
		    position: relative;
		    margin: 0px auto 8px;
		    width: 76%;
		    font-size: 20px;
		    font-weight: 300;
		    line-height: 32px;
		    text-align: left;
		    list-style: circle;

		    @media ( min-width: 768px ) {
			    font-size: 24px;
			    line-height: 37px;
			    margin-top: 25px;
			    padding: 0px 34px;
		    }

		    @media ( min-width: 1440px ) {
			    font-size: 25px;
			    line-height: 41px;
			    margin-top: 25px;
			    padding: 0px 55px;
		    }

		    li {
		    	margin-top: 15px;

		    	@media ( min-width: 768px ) {
				    margin-top: 18px;
			    }
		    }
		}

		.divLine {
			position: relative;
			margin: 30px auto 0px;
			width: 80%;
			height: 3px;
			background: black;

			@media ( min-width: 768px ) {
			    margin: 50px auto 0px;
			    width: 75%;
		    }
		}
	}

	.sectionFour {

		@media ( min-width: 768px ) {
		    margin-bottom: 2px;
	    }

	    .lastPara {
			li:nth-child(2) {
				margin-bottom: 40px;

				@media ( min-width: 1440px ) {
				    margin-bottom: 80px;
			    }
			}
	    }

	    .processWork {
		    position: relative;
		    margin: 40px auto 120px;
		    width: 74%;
		    padding: 30px 0px;
		    border: 3px solid black;
		    font-family: $mainFont, sans-serif;
		    font-size: 23px;
		    font-weight: 400;
		    letter-spacing: 1px;
		    text-align: center;
		    text-decoration: none;
		    color: black;
		    transition: .4s;

		    @media( min-width: 768px ) {
		        margin: 90px auto 120px;
			    width: 45%;
			    font-size: 31px;
			    padding: 35px 0px; 
		    }

		    @media( min-width: 1440px ) {
			    width: 23%;
			    margin-left: 38%;
			    margin-bottom: 170px;
			    margin-top: 40px;

			    &:hover {
			    	background: black;
			    	transition: .4s;
			    	color: #26ffba;
			    }
		    }

		    a {
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    	width: 100%;
		    	height: 100%;
		    }
		}
	}

	.sectionFour, .sectionThree {
		ul {
			@media ( min-width: 1440px ) {
			    padding: 0px 0px;
		    }

		    @media ( min-width: 1660px ) {
			    padding: 0px 50px;
		    }
		}
	}
}














////////////   CONTACT   ////////////////


.contactContainer {
	background: white;

	h1 {
	    position: relative;
	    margin: 40px auto 0px;
	    width: 85%;
	    height: auto;
	    font-family: $mainFont, sans-serif;
	    font-size: 66px;
	    font-weight: 600;
	    letter-spacing: -1px;
	    text-transform: uppercase;
	    text-align: center;
	    color: black;

	    @media ( min-width: 768px ) {
		    font-size: 110px;
		    margin-top: 120px;
	    }
	}

	h2 {
        position: relative;
	    margin: 25px auto 0px;
	    width: 100%;
	    height: auto;
	    font-family: $mainFont, sans-serif;
	    font-size: 26px;
	    line-height: 43px;
	    font-weight: 300;
	    letter-spacing: 0px;
	    text-align: center;
	    color: black;

	    @media ( min-width: 768px ) {
	        font-size: 41px;
			line-height: 65px;
	    }
	}

	.linkSection {
		position: relative;
		margin: 70px auto 100px;
		width: 86%;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap;

		@media ( min-width: 768px ) {
	        width: 60%;
	    }

	    @media ( min-width: 1440px ) {
	        width: 35%;
	    }

		.linkBox {
			position: relative;
			margin: 0 auto;
			width: 90px;
			height: 90px;

			@media ( min-width: 768px ) {
		        width: 140px;
				height: 140px;
		    }

		    @media ( min-width: 1440px ) {
		        width: 120px;
				height: 120px;
				filter: grayscale(1);
				transition: .3s;
				transform: scale(1);

				&:hover {
					filter: grayscale(0);
					transition: .3s;
					transform: scale(1.3);
				}
		    }

			a {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}

		.liBox {	
			background: url(../assets/thumbs/linkedin.svg) no-repeat;
			background-size: 90%;
			background-position: center;
		}

		.gmailBox {	
			background: url(../assets/thumbs/gmail.png) no-repeat;
			background-size: contain;
			background-position: center;
		}
	}
}










////////////   ABOUT   ////////////////





.aboutContainer {

	.mainPhoto {
		background: url(../assets/thumbs/aboutPhoto.jpg) no-repeat;
		background-size: cover;
		position: relative;
		margin: 5px auto 0px;
		width: 97%;
		height: 260px;

		@media ( min-width: 768px ) {
			width: 98.5%;
			height: 500px;
			background-position: center;
			margin: 25px auto 0px;
	    }

	    @media ( min-width: 1440px ) {
			width: 99%;
			height: 600px;
	    }
	}

	h2 {
		position: relative;
	    margin: 0px auto;
	    padding: 50px 0px 40px;
	    width: 97%;
	    height: auto;
	    font-family: $mainFont, sans-serif;
	    font-size: 52px;
	    line-height: 43px;
	    font-weight: 600;
	    letter-spacing: 0px;
	    text-align: center;
	    color: black;
	    background: white;

	    @media ( min-width: 768px ) {
			padding: 90px 0px 60px;
			font-size: 90px;
			width: 98.5%;
	    }

	    @media ( min-width: 1440px ) {
			width: 99%;
	    }
	}

	h3 {
		position: relative;
		margin: 0px auto;
		padding: 0px 20px 0px 27px;
		width: 97%;
		height: auto;
		font-family: $mainFont, sans-serif;
		font-size: 20px;
		line-height: 32px;
		font-weight: 300;
		letter-spacing: 0px;
		text-align: left;
		color: black;
		background: white;

		@media ( min-width: 768px ) {
			width: 98.5%;
			font-size: 24px;
			line-height: 37px;
			padding: 0px 120px 0px 127px;
	    }

	    @media ( min-width: 1440px ) {
		    width: 99%;
		    padding: 10px 20% 0px 20%;
		    font-size: 26px;
		    line-height: 43px;
	    }
	}

	h1 {
		position: relative;
	    margin: 0px auto;
	    padding: 65px 0px 35px;
	    width: 97%;
	    height: auto;
	    font-family: $mainFont, sans-serif;
	    font-size: 52px;
	    line-height: 43px;
	    font-weight: 600;
	    letter-spacing: 0px;
	    text-align: center;
	    color: black;
	    background: white;

	    @media ( min-width: 768px ) {
	    	width: 98.5%;
			padding: 100px 0px 60px;
			font-size: 90px;
	    }

	    @media ( min-width: 1440px ) {
			width: 99%;
	    }
	}

	ul {
	    position: relative;
	    margin: 0px auto;
	    padding-bottom: 60px;
	    padding-left: 12%;
	    width: 97%;
	    height: auto;
	    font-family: $mainFont, sans-serif;
	    font-size: 24px;
	    line-height: 53px;
	    font-weight: 300;
	    letter-spacing: 0px;
	    text-align: left;
	    color: black;
	    background: white;
	    list-style: circle;

	    @media ( min-width: 768px ) {
    	    width: 98.5%;
		    font-size: 29px;
		    line-height: 64px;
		    padding: 0px 15% 80px 31%;
	    }

	    @media ( min-width: 1440px ) {
			width: 99%;
			padding: 0px 20% 80px 39%;
	    }
	}
}



































